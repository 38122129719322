// Needed for redux-saga es6 generator support
// import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./modules";
import * as serviceWorker from "./utils/serviceWorker";

// Import all the third party stuff

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import "sanitize.css/sanitize.css";
import history from "./utils/history";
import store from "./store";
// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
// import "!file-loader?name=[name].[ext]!../public/favicon.ico";
import "file-loader?name=.htaccess!./utils/.htaccess";
/* eslint-enable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
const MOUNT_NODE = document.getElementById("root");

export const Wrapper: React.FC = (props) => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                {props.children}
            </ConnectedRouter>
        </Provider>
    );
};
ReactDOM.render(
    <Wrapper>
        <App />
    </Wrapper>,
    MOUNT_NODE,
);

if (module.hot && MOUNT_NODE) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    // module.hot.accept(['./modules'], () => {
    //     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // });
    module.hot.accept();
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
    // tslint:disable-next-line: no-var-requires
    require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
